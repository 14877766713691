
























import Vue from 'vue'
import moment from 'moment/moment'
import config from '@/utils/config'
import filterMenu from '@/utils/baseData/filterMenu'
import sysMenu from '@/utils/baseData/sys_manage_menu'

export default Vue.extend({
    data() {
        return {
            barList: []as any,
            checkIndex: 0,
        }
    },
    created() {
        // console.log(this.$store.state)
        if (this.$store.state.current) {
            this.barList = filterMenu(sysMenu)
            // this.barList = sysMenu
            // console.log(this.barList)
            return true
        }
        // return false
        // this.barList = filterMenu(sysMenu)
    },
    methods: {
        checkBar(index: any) {
            this.checkIndex = index
            if (index === 0) {
                if (window.location.pathname !== '/sys_manage/area') {
                    this.$router.push('/sys_manage/area')
                }
            }
            if (index === 1) {
                if (window.location.pathname !== '/sys_manage/publishRole') {
                    this.$router.push('/sys_manage/publishRole')
                }
            }
            if (index === 2) {
                if (window.location.pathname !== '/sys_manage/oilInfo') {
                    this.$router.push('/sys_manage/oilInfo')
                }
            }
            // if (index === 3) {
            //     if (window.location.pathname !== '/sys_manage/files') {
            //         this.$router.push('files')
            //     }
            // }
        },
    },
})
