import store from '@/store'
import { Current } from '@/types'
class Authority {
  public hasByStart(str: string) {
    const patternSet = this._getPatternSet()
    for (const pattern of patternSet) {
      if (pattern.startsWith(str)) {
        return true
      }
    }
    return false
  }
  public hasRight(str: string) {
    // console.log(str)
    const patternSet = this._getPatternSet()
    if (patternSet.includes(str)) {
      return true
    }
    return false
  }
  public hasRightOr(...strs: string[]) {
    if (!strs || strs.length === 0) {
      return true
    }
    const patternSet = this._getPatternSet()
    for (const str of strs) {
      if (patternSet.includes(str)) {
        return true
      }
    }
    return false
  }
  /**
   * 是否有服务产品权限
   */
  public hasProduct() {
    return this.hasByStart('/sp/')
  }
  private _getPatternSet() {
    const current: Current|null = store.state.current
    // console.log(current)
    if (!current) {
      return []
    }
    const { patternSet = [] } = current
    return patternSet
  }
}
export default new Authority()
