import authority from '@/utils/baseData/authority'

// 根据权限过滤菜单
export default (menuList: any) => {

  const newList = []
  // console.log(menuList)
  for (const menu of menuList) {
    const { children, right, ...rest } = menu
    // console.log(children)
    const newChildren = []
    if (children && children.length > 0) {
      for (const child of children) {
        if (!child.right || authority.hasRight(child.right)) {
          // console.log(child)
          newChildren.push(child)
        }
      }
      // console.log(newChildren)
      if (newChildren && newChildren.length > 0) {
        newList.push({children: newChildren, right, ...rest})
      }
    } else {
      if (!right || authority.hasRight(right)) {
        newList.push({right, ...rest})
      }
    }
  }
  // console.log('Filter Menu result:', newList)
  return newList
}
