export default [
  {
    name: '4',
    text: '系统管理',
    iconType: 'ios-navigate',
    children: [
      {
        name: '4-1',
        text: '区域管理',
        iconType: 'ios-body-outline',
        to: '/sys_manage/area',
        right: '/sys/area/**',
      }, {
        name: '4-2',
        text: '单位管理',
        iconType: 'ios-body-outline',
        to: '/sys_manage/office',
        right: '/sys/office/**',
      }, {
        name: '4-3',
        text: '系统用户',
        iconType: 'ios-body-outline',
        to: '/sys_manage/user',
        right: '/sys/user/**',
      }, {
        name: '4-4',
        text: '系统角色',
        iconType: 'ios-book-outline',
        to: '/sys_manage/role',
        right: '/sys/role/**',
      }, {
        name: '4-5',
        text: '系统资源',
        iconType: 'ios-build-outline',
        to: '/sys_manage/res',
        right: '/sys/res/**',
      },
      // {
      //   name: '4-6',
      //   text: '信息维护',
      //   iconType: 'ios-infinite-outline',
      //   to: '/main/sys_manage/infor_manager',
      //   right: '/sys/infor_manager/**',
      // },
      {
        name: '4-7',
        text: '日志查询',
        iconType: 'ios-infinite-outline',
        to: '/sys_manage/operate_log',
        right: '/sys/log/page',
      },
    ],
  },
  {
    name: '5',
    text: '阈值管理',
    iconType: 'ios-navigate',
    children: [
      {
        name: '5-1',
        text: '发布角色',
        iconType: 'md-settings',
        to: '/sys_manage/publishRole',
        right: '/respond/respond_role/**',
      },
      {
        name: '5-2',
        text: '发布对象',
        iconType: 'md-settings',
        to: '/sys_manage/publishMember',
        right: '/respond/respond_person/**',
      },
      {
        name: '5-3',
        text: '阈值设置',
        iconType: 'md-settings',
        to: '/sys_manage/threshold',
        right: '/respond/threshold/**',
      },
    ],
  },
  {
    name: '6',
    text: '信息维护',
    iconType: 'ios-navigate',
    children: [
      {
        name: '6-1',
        text: '油库',
        iconType: 'md-settings',
        to: '/sys_manage/oilInfo',
        right: '/sys/log/page',
      },
      {
        name: '6-2',
        text: '大气电场仪',
        iconType: 'md-settings',
        to: '/sys_manage/eLectricInfo',
        right: '/sys/log/page',
      },
      {
        name: '6-3',
        text: '闪电距离报警阈值配置',
        iconType: 'md-settings',
        to: '/sys_manage/warnRange',
        right: '/sys/log/page',
      },
      {
        name: '6-4',
        text: '电场强度报警阈值配置',
        iconType: 'md-settings',
        to: '/sys_manage/intensity',
        right: '/sys/log/page',
      },
    ],
  },
]
